/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/privacy-policy',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/terms-and-conditions',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot-password',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/account/:tab',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/clients',
        component: lazy(() => import('src/views/Toolit/Clients/ClientsList'))
      },
      {
        exact: true,
        path: '/app/clients/:id/:tab',
        component: lazy(() => import('src/views/Toolit/Clients/ClientsDetail'))
      },
      {
        exact: true,
        path: '/app/competitors',
        component: lazy(() => import('src/views/Toolit/Competitors/CompetitorsList'))
      },
      {
        exact: true,
        path: '/app/competitors/:id/:tab',
        component: lazy(() => import('src/views/Toolit/Competitors/CompetitorsDetail'))
      },
      {
        exact: true,
        path: '/app/opportunities',
        component: lazy(() => import('src/views/Toolit/Opportunities/OpportunitiesList'))
      },
      {
        exact: true,
        path: '/app/opportunities/:id/:tab',
        component: lazy(() => import('src/views/Toolit/Opportunities/OpportunitiesDetail'))
      },
      {
        exact: true,
        path: '/app/requests',
        component: lazy(() => import('src/views/Toolit/Requests/RequestsList'))
      },
      {
        exact: true,
        path: '/app/requests/:id/:tab',
        component: lazy(() => import('src/views/Toolit/Requests/RequestsDetail'))
      },
      {
        exact: true,
        path: '/app/requests/:parentId/offers/:id/:tab',
        component: lazy(() => import('src/views/Toolit/Requests/RequestsDetail/Offers/OffersDetail'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    guard: GuestGuard,
    routes: [
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
