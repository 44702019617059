import axios from 'src/utils/axios';
import authService from 'src/services/authService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const UPDATE_PASSWORD = '@account/update-password';
export const UPDATE_PHOTO = '@account/update-photo';

export function login(username, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithUsernameAndPassword(username, password);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register() {
  return true;
}

export function updateProfile(update) {
  const request = axios.patch('/auth/me/', { update });

  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_PROFILE,
      payload: {
        user: response.data
      }
    }));
  };
}


export function updatePassword(update) {
  const request = axios.post('/auth/me/set-password', { update });

  return (dispatch) => {
    request.then(() => dispatch({
      type: UPDATE_PASSWORD
    }));
  };
}

export function updatePhoto(file) {
  const formData = new FormData();
  formData.append('file', file.file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  const request = axios.post('/auth/me/set-photo', formData, config);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_PHOTO,
      payload: {
        user: response.data
      }
    }));
  };
}
